.Loading {
  background-color: rgba(255,255,255,.9);
  display: table;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 99999;
}
.Loading-container {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.Loading-box {
  background: #FFF;
  box-shadow: 0px 1px 10px 0px rgba(0,0,0,.4);
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  margin: 0 auto;
  padding: 8px;
  min-width: 160px;
  max-width: 400px;
  text-align: left;
}
.Loading-box--small {
  border-radius: 100px;
  text-align: center;
}
.Loading-text {
  margin-bottom: 10px;
}
.Loading-progress {
  background: url(../../img/loading.gif) no-repeat center center;
  height: 16px;
}
