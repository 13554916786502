/* Reset for full height */
html,
body {
  background-color: color-grey-light;
  margin: 0;
  padding: 0;
  height: 100%;
}

.input-login-font-section{
  min-width: 3em;
}

.justify-text
  text-align justify;

.justify-left-text
  text-align left;

/* Signin Component */
.Signin {
  min-height: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  width: 100%;

  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;

  &-logo {
    margin: 0 auto 20px;
  }

  &-form {
    background-color color-white
    border-radius 7px
    padding 20px
    margin 0 auto
    max-width 340px
    box-shadow 0 0 15px 6px rgba(0,0,0,.2)
  }

  &-infoBox {
    background-color color-white
    border-radius 7px
    padding 20px 20px 5px 20px
    margin 0 auto
    max-width 340px
    font-size 13px
    box-shadow 0 0 15px 6px rgba(0,0,0,.2)
  }

  &-formBottom {
    margin-top: 14px;
  }

  &-linkForgot {
    color: color-blue;
    font-size: 13px;
    text-decoration: none;
    &:hover {
      color: color-blue-dark;
      text-decoration: underline;
    }
  }

  &-alerts {
    max-width: 342px;
    margin: 5px auto;
    text-align: center;
  }

  &-IE,
  &-incorrect {
    margin: 5px 0;
  }

  &-IE {
    display: none;
  }

  &-footer {
    margin-top: 50px;
    width: 100%;
    text-align: center;
  }
  &-footerBox {
    margin-bottom: 20px;
  }

  &-linkLegal {
    display: inline-block;
    font-size: 11px;
    font-weight: bold;
    margin: 0 10px;
  }

  &-linkSocial {
    background-color: color-blue;
    border-radius: 100%;
    color: color-white;
    display: inline-block;
    padding: 6px;
    width: 33px;
    height: 33px;
    &:hover {
      background-color: color-blue-dark;
      color: color-white;
    }
  }

  #latest-news-container {
    background-color: #fff
    border-radius: 7px
    padding: 2px 15px 5px 15px
    margin: 15px auto
    max-width: 340px
    box-shadow: 0 0 15px 6px rgba(0,0,0,.2)
    display: none
    .latest-news-title-container {
      text-align: center
      margin-top: 15px
      margin-bottom: 10px
      position: relative
      .latest-news-title {
        padding: 8px 25px
        border-bottom: solid 2px #007cab
        font-size: 16px
        font-weight: bold
      }
    }
    #latest-blog-post-container {
      max-height: 180px
      overflow-y: hidden
      &:hover {
        overflow-y: auto !important;
      }
      .blog-post {
        padding: 10px 5px 10px 15px
        border-bottom: 1px solid rgba(0, 0, 0, .2)
        margin: 0px
        display: flex
        align-items: center

        .fa-book {
          margin-top: 5px
          margin-right: 21px
          margin-left: -10px
          font-size: 32px
          color: #007cab
        }

        .blog-post-title {
          max-height: 40px
          -webkit-line-clamp: 2
          /* autoprefixer: off */
          -webkit-box-orient: vertical
          -moz-box-orient: vertical
          /* autoprefixer: on */
          overflow: hidden
          text-overflow: ellipsis
          display: -webkit-box
          color: #666

          a {
            color: #666
          }
        }

        &:last-child {
          border-bottom: none
        }
      }
    }
  }

  .Signin-footer {
    margin-top: 10px
    .Signin-footerBox {
      margin-bottom: 8px
      margin-top: 5px
    }
    .row-footer-login {
      margin-top: 15px
    }
  }

  @media (min-width: screen-md-min) {

    &-form {
      padding: 20px 30px;
    }

  }

  @media (max-height: 750px) {
    .latest-news-container-with-login-errors {
        max-height: 120px !important
      }
    }
  }

  @media (max-height: 600px) {
    #latest-news-container {
      #latest-blog-post-container {
        max-height: 60px !important
      }
    }
  }

@media (max-height: 560px) {
    #latest-news-container {
      display: none !important
    }
}

.Signin-IE, .Signin-incorrect {
  margin: 15px 0px 0px 0px;
}

// Divide in two part login

.link-color
  color #fff

.link-color:hover
  color #fff

.wrap-login
  overflow-y hidden
  width 100%
  height 100%

.login-float-left
  float left
  width 55%
  background #1386ac
  height 100%

.login-float-right
  float right
  background #fff
  height 100%
  width 45%

.img-resize-login
  background-image url(https://staticmkt.kometsales.com/default-image-login-page.jpg)
  background-repeat no-repeat
  background-size 100% 100%
  width 100%
  height 100%

.row-footer-login
  position absolute
  top 92%
  width 55%
  margin auto -2%
  display block

.img-logo
  background-repeat no-repeat
  width 270px
  height 64px
  background-repeat no-repeat

.img-logo-companies
  background-repeat no-repeat
  background-size 275px 80px
  display block
  height 80px

.img-logo-billdoran
  margin auto 14%
  display block
  width 220px
  height 125px
  background-repeat no-repeat
  background-size 213px 111px

.img-logo-mayesh
  margin auto 21%
  display block
  width 220px
  height 165px
  background-repeat no-repeat
  background-size 162px 148px

.img-logo-kennicott
  margin 10px 5px 5px
  display block
  width 270px
  height 80px
  background-repeat no-repeat

.img-logo-splendor
  margin 0px 26px 5px
  display block
  height 122px
  background-repeat no-repeat

.img-logo-perrifarms
  margin 10px 10px 20px 10px
  display block
  width 270px
  height 95px
  background-repeat round

.img-logo-petalsdirect
  margin 10px 10px 20px 10px
  display block
  width 270px
  height 95px
  background-repeat round

.img-logo-lasvegas
  margin 10px 10px 20px 10px
  display block
  width 270px
  height 95px
  background-repeat round

.img-logo-camflor
  margin 10px 10px 20px 10px
  display block
  width 270px
  height 200px
  background-repeat round

.img-logo-southernfloral
  height 100px
  background-size 90% 100%
  background-repeat no-repeat
  margin-bottom 15px
  margin-left 10px

.img-logo-kdcompanies
  background-image url(https://staticmkt.kometsales.com/kdcompanies/logo-kdcompany.png) !important
  background-repeat no-repeat
  background-size contain
  margin 10px 0px 9px
  display block
  width 100%
  height 82px !important

.img-logo-pikespeakfloral
  height 150px
  background-size 90% 100%
  background-repeat no-repeat
  margin-bottom 15px
  margin-left 10px

.img-logo-lasflores
  height 155px
  background-size 100%
  background-repeat no-repeat

.img-logo-jetfresh
  height 125px
  background-size 100%
  background-repeat no-repeat

.img-logo-frescafarms
  height 140px
  background-size 90% 100%
  background-repeat no-repeat
  margin-left 25px

.img-logo-sunvalley
  height: 70px
  background-size: 280px
  background-repeat no-repeat
  margin-bottom 15px

.img-logo-procurement
  background-repeat no-repeat
  background-size contain
  margin 10px 0 9px
  display block
  width 100%
  height 61px !important

#zenbox_tab
  top 40% !important

#us_report_button
  position relative
  top -26em
  display block
  margin 0px 0px -10px
  height 40px
  width 51px
  border-left-width 2px
  border-left-style solid
  border-left-color rgb(255, 255, 255)
  border-right-width 2px
  border-right-style solid
  border-right-color rgb(255, 255, 255)
  border-bottom-width 3px
  border-bottom-style solid
  border-bottom-color rgb(255, 255, 255)
  background-color #717175

.mayesh
  background-size cover
  background-image url(https://s3.amazonaws.com/marketing.app.kometsales.com/mayesh/bg-login-mayesh-select.jpg)
  background-repeat no-repeat
  background-position 50% 50%

.splendor
  background-size cover
  background-image url(https://s3.amazonaws.com/marketing.app.kometsales.com/splendor/bg-login-splendor-select.jpg)
  background-repeat no-repeat
  background-position 50% 50%

.kdcompanies
  background-size cover
  background-image url(https://staticmkt.kometsales.com/kdcompanies/bg-login-kdcompanies-select.jpg)
  background-repeat no-repeat
  background-position 50% 50%

.kennicott
  background-color #960048

.ecommerce_default
  background-color #F6FAFF
  font-family Inter
  letter-spacing 0
  .Signin-form
    width 580px
    max-width 580px
    border-radius var(--radius-lg, 12px)
    box-shadow 0 20px 24px -4px #15151514
    gap 40px
    padding 48px 56px
    display flex
    flex-direction column
    .heading
      gap 4px
      .heading-title
        span
          font-weight 700
          font-size 30px
          line-height 166%
          color: var(--text-primary, #171A1C)
      .heading-subtitle
        span
          font-weight 400
          font-size 16px
          line-height 150%
          color: var(--text-tertiary, #555E68)
    .inputs
      .form-label
        padding-bottom 6px
        font-weight 500
        font-size 14px
        line-height 150%
        color: var(--text-primary, #171A1C);
      .form-group
        input
          border-radius var(--radius-sm, 6px)
          border 1px solid var(--neutral-outlined-Border, #CDD7E1)
          height 52px
          font-weight 400
          font-size 18px
          line-height 155%
          box-shadow none
        input::placeholder
          font-weight 400
          font-size 18px
          line-height 155%
        input:hover
          background #f7f8fa
        .form-password
          position relative
          input
            padding-right 40px
          .icon-show-pwd
            position absolute
            right 10px
            top 50%
            transform translateY(-50%)
            cursor pointer
        .form-forgot-pwd
          display flex
          justify-content end
        .signin-incorrect
          min-height 28px
          font-weight 400
          font-size 16px
          line-height 150%
          color var(--danger-plain-Color, #C41C1C)
        .btn-forgot-pwd
          min-height 32px
          padding-top 6px
          background-color white
          border none
          color var(--primary-plain-Color, #0B95CB)
          font-weight 600
          font-size 12px
          line-height 12px
          text-decoration none
          cursor pointer
      .btn-login
        min-height 48px
        border-radius var(--radius-sm, 6px)
        padding 6px 24px
        background var(--primary-solid-Bg, #0B95CB)
        color white
        font-weight 600
        font-size 16px
        line-height 16px
        width 100%
        border none
      .btn-login:hover
        background #187da5
  .inputs-error
    input
      border 1px solid var(--danger-outlined-Border, #F09898) !important
    input::placeholder
      color var(--danger-outlined-Color, #C41C1C) !important
  .register-links
    display flex
    justify-content center
    align-items center
    padding-top 8px
    margin-top 8px
    height 56px
    span
      font-weight 400
      font-size 16px
      line-height 150%
      color var(--text-secondary, #32383E)
    a
      font-weight 600
      font-size 16px
      line-height 16px
      color var(--primary-plain-Color, #0B95CB)
      margin-left 8px
      text-decoration none
      cursor pointer
  .powered-by
    margin-top 40px
    display flex
    justify-content center
    span
      font-weight 500
      font-size 11px
      line-height 150%
      color var(--text-tertiary, #555E68)
    img
      width 50px
      height 16px
      mix-blend-mode Luminosity
      margin-left 4px

.kennicott-text-color
  color #960048 !important

.lasflores
  background #E5EEBA

.lasflores
  .btn
    &.btn-primary
      background-color #000000
      border none

.jetfresh
  background #2BB673

.jetfresh
  .btn
    &.btn-primary
      background-color #1D7B4E
      border none

.frescafarms
  background #008951

.frescafarms
  .btn
    &.btn-primary
      background-color #573F98
      border none

.pikespeakfloral
  background-size cover
  background-image url(https://s3.amazonaws.com/marketing.app.kometsales.com/pikespeakfloral/bg-pikespeakfloral.jpg)
  background-color:#FFF
  background-repeat no-repeat
  background-position 50% 50%

.pikespeakfloral
  .btn
    &.btn-primary
      background-color #00b19d
      border none

.southernfloral
  background-size cover
  background-image url(https://s3.amazonaws.com/marketing.app.kometsales.com/southernfloral/bg-login.jpg)
  background-repeat no-repeat
  background-position 50% 50%

.southernfloral
  .btn
    &.btn-primary
      background-color #82af0c
      border none

.sunvalley
  background-color #0c456e

.sunvalley
  .btn
    &.btn-primary
      background-color #0c456e
      border none

.perrifarms
  background-size cover
  background-image url(https://s3.amazonaws.com/marketing.app.kometsales.com/perrifarms/perrifarms.jpg)
  background-repeat no-repeat
  background-position 50% 50%
  .btn
    &.btn-primary
      background linear-gradient(to bottom, rgba(207,83,89,1) 0%, rgba(166,21,28,1) 50%, rgba(207,83,89,1) 100%);
      border none


.petalsdirect
  background-size cover
  background-image url(https://s3.amazonaws.com/marketing.app.kometsales.com/petalsdirect/bg-petals-direct.png)
  background-repeat no-repeat
  background-position 50% 50%
  .btn
  &.btn-primary
    background #373250
    border none

.lasvegas
  background-size cover
  background-image url(https://s3.amazonaws.com/marketing.app.kometsales.com/lasvegas/bg-las-vegas.png)
  background-repeat no-repeat
  background-position 50% 50%
  .btn
  &.btn-primary
    background #1b4444
    border none

.camflor
  background-size cover
  background-image url(https://s3.amazonaws.com/marketing.app.kometsales.com/camflor/bg-camflor.png)
  background-repeat no-repeat
  background-position 50% 50%
  .btn
  &.btn-primary
    background #FB4A00
    border none

.procurement
  background-color #000

.procurement .btn.btn-primary
  background-color #000
  border none
  border-radius 12px
  width 100px
  float right
  padding 3px

.procurement .btn.btn-primary:hover
  background-color #3b3b3b

.procurement .Signin-linkForgot
  color #000

.procurement .Signin-formBottom
  margin-left -45px

.procurement .Signin-footerBox
  color #fff

.procurement .row.row-footer-login
  width 100%
  margin auto

@media (max-width: screen-md-min)
  .login-float-left
    width 100%

  .login-float-right
    width 0%
    display none

  .row-footer-login
    width 51em
    margin auto 0%
    display block

.margin-top-4
  margin-top 4px

.margin-left-22
  margin-left 22px !important

.blue-ks-button-rounded
  border-radius 12px
  font-family "Helvetica Neue", Helvetica, Arial, sans-serif
  background #1386ac
  color white
  padding 0px 22px
  border 0px
  height 25px
  outline none !important

.download-app-div
  margin-top 5px;

.download-app
  float right;
  margin-left -15px;

.download-app-apple
  height 31px;

.login
  border-radius 12px
  float: right
  width 100px

.accept
  margin 0px auto 0px 180px
  width 65px

.decline
  margin 0px auto 0px 180px
  width 65px
  background #ffffff
  color black
  border-color #cccccc

.blue-ks-button-rounded:hover,
.blue-ks-button-rounded:active,
.blue-ks-button-rounded:focus
  background: #59b0cb
  text-decoration: none
  transition: all ease .35s

.default-ks-button-rounded
  border-radius 12px
  font-family "Helvetica Neue", Helvetica, Arial, sans-serif
  background #ffffff
  color black
  padding 0px 22px
  height 25px
  border 1px solid #cccccc
  outline none !important

.default-ks-button-rounded:hover,
.default-ks-button-rounded:active,
.default-ks-button-rounded:focus
  background: #e6e6e6
  text-decoration: none
  transition: all ease .35s

.icon-info
  color #0084ae
  font-size 20px

.not-found-user-header
  margin-left 5px
  color #888888
  font-weight bold

.not-found-user-bottom
  color #939393
  font-weight normal
  margin-left 25px


.success-mail-send-text
  color #939393
  font-weight normal
  margin-left 5px

.custom-ks-dialog
  .modal-dialog
    margin-top 15%
    width 550px
    .modal-content
      border-radius 0px 0px 6px 6px
      border-bottom-left-radius 15px
      border-bottom-right-radius 15px
      .modal-header
        background-color #1386ac
        height 35px
        border-top-left-radius 0px
        border-top-right-radius 0px
        padding 6px 15px 25px 15px
      .bootstrap-dialog-header
        .bootstrap-dialog-close-button
          .close
            opacity 1
            color #fff
    .modal-body
      padding 15px 45px 0px 45px
    .modal-footer
      border-top 0px
      padding-bottom 17px
      .bootstrap-dialog-footer
        .bootstrap-dialog-footer-buttons
          padding-right 30px

.forgot-password
  .modal-dialog
    width 586px
    .modal-content
      .modal-footer
        .bootstrap-dialog-footer
          .bootstrap-dialog-footer-buttons
            margin-right 8px

.forgot-password-modal
  .modal-dialog
    width 300px
  .modal-content
    .modal-header
      .close
        opacity 1
        color #fff
      .modal-title
        font-size 16px
        color white
    .modal-footer
      margin-right 30px

.center-buttons-footer-modal
  text-align center
  margin-right 1px !important

.confirmation-dialog
  .modal-dialog
    width 530px

.margin-top-15
  margin-top 15px

.text-explanation
  padding 0px 13px;

.forgot-password-text
  font-family "Helvetica Neue",Helvetica,Arial,sans-serif !important
  font-size 14px !important
  line-height 1.42857143 !important

.blue-ks
  background #1386ac !important

.white-text-ks
  color: #fff !important


.btn-signin
  display inline-block !important
  font-size 14px !important
  font-weight 400 !important
  text-align center !important
  white-space nowrap !important
  touch-action manipulation !important
  cursor pointer !important
  user-select none !important
  font-family "Helvetica Neue",Helvetica,Arial,sans-serif !important
  padding 0 22px !important
  outline 0!important
