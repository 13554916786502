html,
body {
  background-color: #fbfbfb;
  margin: 0;
  padding: 0;
  height: 100%;
}
.input-login-font-section {
  min-width: 3em;
}
.justify-text {
  text-align: justify;
}
.justify-left-text {
  text-align: left;
}
.Signin {
  min-height: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.Signin-logo {
  margin: 0 auto 20px;
}
.Signin-form {
  background-color: #fff;
  border-radius: 7px;
  padding: 20px;
  margin: 0 auto;
  max-width: 340px;
  box-shadow: 0 0 15px 6px rgba(0,0,0,0.2);
}
.Signin-infoBox {
  background-color: #fff;
  border-radius: 7px;
  padding: 20px 20px 5px 20px;
  margin: 0 auto;
  max-width: 340px;
  font-size: 13px;
  box-shadow: 0 0 15px 6px rgba(0,0,0,0.2);
}
.Signin-formBottom {
  margin-top: 14px;
}
.Signin-linkForgot {
  color: #3c8dc5;
  font-size: 13px;
  text-decoration: none;
}
.Signin-linkForgot:hover {
  color: #23527c;
  text-decoration: underline;
}
.Signin-alerts {
  max-width: 342px;
  margin: 5px auto;
  text-align: center;
}
.Signin-IE,
.Signin-incorrect {
  margin: 5px 0;
}
.Signin-IE {
  display: none;
}
.Signin-footer {
  margin-top: 50px;
  width: 100%;
  text-align: center;
}
.Signin-footerBox {
  margin-bottom: 20px;
}
.Signin-linkLegal {
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  margin: 0 10px;
}
.Signin-linkSocial {
  background-color: #3c8dc5;
  border-radius: 100%;
  color: #fff;
  display: inline-block;
  padding: 6px;
  width: 33px;
  height: 33px;
}
.Signin-linkSocial:hover {
  background-color: #23527c;
  color: #fff;
}
.Signin #latest-news-container {
  background-color: #fff;
  border-radius: 7px;
  padding: 2px 15px 5px 15px;
  margin: 15px auto;
  max-width: 340px;
  box-shadow: 0 0 15px 6px rgba(0,0,0,0.2);
  display: none;
}
.Signin #latest-news-container .latest-news-title-container {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
  position: relative;
}
.Signin #latest-news-container .latest-news-title-container .latest-news-title {
  padding: 8px 25px;
  border-bottom: solid 2px #007cab;
  font-size: 16px;
  font-weight: bold;
}
.Signin #latest-news-container #latest-blog-post-container {
  max-height: 180px;
  overflow-y: hidden;
}
.Signin #latest-news-container #latest-blog-post-container:hover {
  overflow-y: auto !important;
}
.Signin #latest-news-container #latest-blog-post-container .blog-post {
  padding: 10px 5px 10px 15px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  margin: 0px;
  display: flex;
  align-items: center;
}
.Signin #latest-news-container #latest-blog-post-container .blog-post .fa-book {
  margin-top: 5px;
  margin-right: 21px;
  margin-left: -10px;
  font-size: 32px;
  color: #007cab;
}
.Signin #latest-news-container #latest-blog-post-container .blog-post .blog-post-title {
  max-height: 40px;
  -webkit-line-clamp: 2;
/* autoprefixer: off */
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
/* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  color: #666;
}
.Signin #latest-news-container #latest-blog-post-container .blog-post .blog-post-title a {
  color: #666;
}
.Signin #latest-news-container #latest-blog-post-container .blog-post:last-child {
  border-bottom: none;
}
.Signin .Signin-footer {
  margin-top: 10px;
}
.Signin .Signin-footer .Signin-footerBox {
  margin-bottom: 8px;
  margin-top: 5px;
}
.Signin .Signin-footer .row-footer-login {
  margin-top: 15px;
}
@media (min-width: 992px) {
  .Signin-form {
    padding: 20px 30px;
  }
}
@media (max-height: 750px) {
  .Signin .latest-news-container-with-login-errors {
    max-height: 120px !important;
  }
}
@media (max-height: 600px) {
  #latest-news-container #latest-blog-post-container {
    max-height: 60px !important;
  }
}
@media (max-height: 560px) {
  #latest-news-container {
    display: none !important;
  }
}
.Signin-IE,
.Signin-incorrect {
  margin: 15px 0px 0px 0px;
}
.link-color {
  color: #fff;
}
.link-color:hover {
  color: #fff;
}
.wrap-login {
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}
.login-float-left {
  float: left;
  width: 55%;
  background: #1386ac;
  height: 100%;
}
.login-float-right {
  float: right;
  background: #fff;
  height: 100%;
  width: 45%;
}
.img-resize-login {
  background-image: url("https://staticmkt.kometsales.com/default-image-login-page.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}
.row-footer-login {
  position: absolute;
  top: 92%;
  width: 55%;
  margin: auto -2%;
  display: block;
}
.img-logo {
  background-repeat: no-repeat;
  width: 270px;
  height: 64px;
  background-repeat: no-repeat;
}
.img-logo-companies {
  background-repeat: no-repeat;
  background-size: 275px 80px;
  display: block;
  height: 80px;
}
.img-logo-billdoran {
  margin: auto 14%;
  display: block;
  width: 220px;
  height: 125px;
  background-repeat: no-repeat;
  background-size: 213px 111px;
}
.img-logo-mayesh {
  margin: auto 21%;
  display: block;
  width: 220px;
  height: 165px;
  background-repeat: no-repeat;
  background-size: 162px 148px;
}
.img-logo-kennicott {
  margin: 10px 5px 5px;
  display: block;
  width: 270px;
  height: 80px;
  background-repeat: no-repeat;
}
.img-logo-splendor {
  margin: 0px 26px 5px;
  display: block;
  height: 122px;
  background-repeat: no-repeat;
}
.img-logo-perrifarms {
  margin: 10px 10px 20px 10px;
  display: block;
  width: 270px;
  height: 95px;
  background-repeat: round;
}
.img-logo-petalsdirect {
  margin: 10px 10px 20px 10px;
  display: block;
  width: 270px;
  height: 95px;
  background-repeat: round;
}
.img-logo-lasvegas {
  margin: 10px 10px 20px 10px;
  display: block;
  width: 270px;
  height: 95px;
  background-repeat: round;
}
.img-logo-camflor {
  margin: 10px 10px 20px 10px;
  display: block;
  width: 270px;
  height: 200px;
  background-repeat: round;
}
.img-logo-southernfloral {
  height: 100px;
  background-size: 90% 100%;
  background-repeat: no-repeat;
  margin-bottom: 15px;
  margin-left: 10px;
}
.img-logo-kdcompanies {
  background-image: url("https://staticmkt.kometsales.com/kdcompanies/logo-kdcompany.png") !important;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 10px 0px 9px;
  display: block;
  width: 100%;
  height: 82px !important;
}
.img-logo-pikespeakfloral {
  height: 150px;
  background-size: 90% 100%;
  background-repeat: no-repeat;
  margin-bottom: 15px;
  margin-left: 10px;
}
.img-logo-lasflores {
  height: 155px;
  background-size: 100%;
  background-repeat: no-repeat;
}
.img-logo-jetfresh {
  height: 125px;
  background-size: 100%;
  background-repeat: no-repeat;
}
.img-logo-frescafarms {
  height: 140px;
  background-size: 90% 100%;
  background-repeat: no-repeat;
  margin-left: 25px;
}
.img-logo-sunvalley {
  height: 70px;
  background-size: 280px;
  background-repeat: no-repeat;
  margin-bottom: 15px;
}
.img-logo-procurement {
  background-repeat: no-repeat;
  background-size: contain;
  margin: 10px 0 9px;
  display: block;
  width: 100%;
  height: 61px !important;
}
#zenbox_tab {
  top: 40% !important;
}
#us_report_button {
  position: relative;
  top: -26em;
  display: block;
  margin: 0px 0px -10px;
  height: 40px;
  width: 51px;
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: #fff;
  border-right-width: 2px;
  border-right-style: solid;
  border-right-color: #fff;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: #fff;
  background-color: #717175;
}
.mayesh {
  background-size: cover;
  background-image: url("https://s3.amazonaws.com/marketing.app.kometsales.com/mayesh/bg-login-mayesh-select.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.splendor {
  background-size: cover;
  background-image: url("https://s3.amazonaws.com/marketing.app.kometsales.com/splendor/bg-login-splendor-select.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.kdcompanies {
  background-size: cover;
  background-image: url("https://staticmkt.kometsales.com/kdcompanies/bg-login-kdcompanies-select.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.kennicott {
  background-color: #960048;
}
.ecommerce_default {
  background-color: #f6faff;
  font-family: Inter;
  letter-spacing: 0;
}
.ecommerce_default .Signin-form {
  width: 580px;
  max-width: 580px;
  border-radius: var(--radius-lg, 12px);
  box-shadow: 0 20px 24px -4px rgba(21,21,21,0.078);
  gap: 40px;
  padding: 48px 56px;
  display: flex;
  flex-direction: column;
}
.ecommerce_default .Signin-form .heading {
  gap: 4px;
}
.ecommerce_default .Signin-form .heading .heading-title span {
  font-weight: 700;
  font-size: 30px;
  line-height: 166%;
  color: var(--text-primary, #171a1c);
}
.ecommerce_default .Signin-form .heading .heading-subtitle span {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--text-tertiary, #555e68);
}
.ecommerce_default .Signin-form .inputs .form-label {
  padding-bottom: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: var(--text-primary, #171a1c);
}
.ecommerce_default .Signin-form .inputs .form-group input {
  border-radius: var(--radius-sm, 6px);
  border: 1px solid var(--neutral-outlined-Border, #cdd7e1);
  height: 52px;
  font-weight: 400;
  font-size: 18px;
  line-height: 155%;
  box-shadow: none;
}
.ecommerce_default .Signin-form .inputs .form-group input::placeholder {
  font-weight: 400;
  font-size: 18px;
  line-height: 155%;
}
.ecommerce_default .Signin-form .inputs .form-group input:hover {
  background: #f7f8fa;
}
.ecommerce_default .Signin-form .inputs .form-group .form-password {
  position: relative;
}
.ecommerce_default .Signin-form .inputs .form-group .form-password input {
  padding-right: 40px;
}
.ecommerce_default .Signin-form .inputs .form-group .form-password .icon-show-pwd {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.ecommerce_default .Signin-form .inputs .form-group .form-forgot-pwd {
  display: flex;
  justify-content: end;
}
.ecommerce_default .Signin-form .inputs .form-group .signin-incorrect {
  min-height: 28px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--danger-plain-Color, #c41c1c);
}
.ecommerce_default .Signin-form .inputs .form-group .btn-forgot-pwd {
  min-height: 32px;
  padding-top: 6px;
  background-color: #fff;
  border: none;
  color: var(--primary-plain-Color, #0b95cb);
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-decoration: none;
  cursor: pointer;
}
.ecommerce_default .Signin-form .inputs .btn-login {
  min-height: 48px;
  border-radius: var(--radius-sm, 6px);
  padding: 6px 24px;
  background: var(--primary-solid-Bg, #0b95cb);
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  border: none;
}
.ecommerce_default .Signin-form .inputs .btn-login:hover {
  background: #187da5;
}
.ecommerce_default .inputs-error input {
  border: 1px solid var(--danger-outlined-Border, #f09898) !important;
}
.ecommerce_default .inputs-error input::placeholder {
  color: var(--danger-outlined-Color, #c41c1c) !important;
}
.ecommerce_default .register-links {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  margin-top: 8px;
  height: 56px;
}
.ecommerce_default .register-links span {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--text-secondary, #32383e);
}
.ecommerce_default .register-links a {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: var(--primary-plain-Color, #0b95cb);
  margin-left: 8px;
  text-decoration: none;
  cursor: pointer;
}
.ecommerce_default .powered-by {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.ecommerce_default .powered-by span {
  font-weight: 500;
  font-size: 11px;
  line-height: 150%;
  color: var(--text-tertiary, #555e68);
}
.ecommerce_default .powered-by img {
  width: 50px;
  height: 16px;
  mix-blend-mode: Luminosity;
  margin-left: 4px;
}
.kennicott-text-color {
  color: #960048 !important;
}
.lasflores {
  background: #e5eeba;
}
.lasflores .btn.btn-primary {
  background-color: #000;
  border: none;
}
.jetfresh {
  background: #2bb673;
}
.jetfresh .btn.btn-primary {
  background-color: #1d7b4e;
  border: none;
}
.frescafarms {
  background: #008951;
}
.frescafarms .btn.btn-primary {
  background-color: #573f98;
  border: none;
}
.pikespeakfloral {
  background-size: cover;
  background-image: url("https://s3.amazonaws.com/marketing.app.kometsales.com/pikespeakfloral/bg-pikespeakfloral.jpg");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.pikespeakfloral .btn.btn-primary {
  background-color: #00b19d;
  border: none;
}
.southernfloral {
  background-size: cover;
  background-image: url("https://s3.amazonaws.com/marketing.app.kometsales.com/southernfloral/bg-login.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.southernfloral .btn.btn-primary {
  background-color: #82af0c;
  border: none;
}
.sunvalley {
  background-color: #0c456e;
}
.sunvalley .btn.btn-primary {
  background-color: #0c456e;
  border: none;
}
.perrifarms {
  background-size: cover;
  background-image: url("https://s3.amazonaws.com/marketing.app.kometsales.com/perrifarms/perrifarms.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.perrifarms .btn.btn-primary {
  background: linear-gradient(to bottom, #cf5359 0%, #a6151c 50%, #cf5359 100%);
  border: none;
}
.petalsdirect {
  background-size: cover;
  background-image: url("https://s3.amazonaws.com/marketing.app.kometsales.com/petalsdirect/bg-petals-direct.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.petalsdirect .btn,
.petalsdirect.btn-primary {
  background: #373250;
  border: none;
}
.lasvegas {
  background-size: cover;
  background-image: url("https://s3.amazonaws.com/marketing.app.kometsales.com/lasvegas/bg-las-vegas.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.lasvegas .btn,
.lasvegas.btn-primary {
  background: #1b4444;
  border: none;
}
.camflor {
  background-size: cover;
  background-image: url("https://s3.amazonaws.com/marketing.app.kometsales.com/camflor/bg-camflor.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.camflor .btn,
.camflor.btn-primary {
  background: #fb4a00;
  border: none;
}
.procurement {
  background-color: #000;
}
.procurement .btn.btn-primary {
  background-color: #000;
  border: none;
  border-radius: 12px;
  width: 100px;
  float: right;
  padding: 3px;
}
.procurement .btn.btn-primary:hover {
  background-color: #3b3b3b;
}
.procurement .Signin-linkForgot {
  color: #000;
}
.procurement .Signin-formBottom {
  margin-left: -45px;
}
.procurement .Signin-footerBox {
  color: #fff;
}
.procurement .row.row-footer-login {
  width: 100%;
  margin: auto;
}
@media (max-width: 992px) {
  .login-float-left {
    width: 100%;
  }
  .login-float-right {
    width: 0%;
    display: none;
  }
  .row-footer-login {
    width: 51em;
    margin: auto 0%;
    display: block;
  }
}
.margin-top-4 {
  margin-top: 4px;
}
.margin-left-22 {
  margin-left: 22px !important;
}
.blue-ks-button-rounded {
  border-radius: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: #1386ac;
  color: #fff;
  padding: 0px 22px;
  border: 0px;
  height: 25px;
  outline: none !important;
}
.download-app-div {
  margin-top: 5px;
}
.download-app {
  float: right;
  margin-left: -15px;
}
.download-app-apple {
  height: 31px;
}
.login {
  border-radius: 12px;
  float: right;
  width: 100px;
}
.accept {
  margin: 0px auto 0px 180px;
  width: 65px;
}
.decline {
  margin: 0px auto 0px 180px;
  width: 65px;
  background: #fff;
  color: #000;
  border-color: #ccc;
}
.blue-ks-button-rounded:hover,
.blue-ks-button-rounded:active,
.blue-ks-button-rounded:focus {
  background: #59b0cb;
  text-decoration: none;
  transition: all ease 0.35s;
}
.default-ks-button-rounded {
  border-radius: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: #fff;
  color: #000;
  padding: 0px 22px;
  height: 25px;
  border: 1px solid #ccc;
  outline: none !important;
}
.default-ks-button-rounded:hover,
.default-ks-button-rounded:active,
.default-ks-button-rounded:focus {
  background: #e6e6e6;
  text-decoration: none;
  transition: all ease 0.35s;
}
.icon-info {
  color: #0084ae;
  font-size: 20px;
}
.not-found-user-header {
  margin-left: 5px;
  color: #888;
  font-weight: bold;
}
.not-found-user-bottom {
  color: #939393;
  font-weight: normal;
  margin-left: 25px;
}
.success-mail-send-text {
  color: #939393;
  font-weight: normal;
  margin-left: 5px;
}
.custom-ks-dialog .modal-dialog {
  margin-top: 15%;
  width: 550px;
}
.custom-ks-dialog .modal-dialog .modal-content {
  border-radius: 0px 0px 6px 6px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.custom-ks-dialog .modal-dialog .modal-content .modal-header {
  background-color: #1386ac;
  height: 35px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 6px 15px 25px 15px;
}
.custom-ks-dialog .modal-dialog .modal-content .bootstrap-dialog-header .bootstrap-dialog-close-button .close {
  opacity: 1;
  color: #fff;
}
.custom-ks-dialog .modal-dialog .modal-body {
  padding: 15px 45px 0px 45px;
}
.custom-ks-dialog .modal-dialog .modal-footer {
  border-top: 0px;
  padding-bottom: 17px;
}
.custom-ks-dialog .modal-dialog .modal-footer .bootstrap-dialog-footer .bootstrap-dialog-footer-buttons {
  padding-right: 30px;
}
.forgot-password .modal-dialog {
  width: 586px;
}
.forgot-password .modal-dialog .modal-content .modal-footer .bootstrap-dialog-footer .bootstrap-dialog-footer-buttons {
  margin-right: 8px;
}
.forgot-password-modal .modal-dialog {
  width: 300px;
}
.forgot-password-modal .modal-content .modal-header .close {
  opacity: 1;
  color: #fff;
}
.forgot-password-modal .modal-content .modal-header .modal-title {
  font-size: 16px;
  color: #fff;
}
.forgot-password-modal .modal-content .modal-footer {
  margin-right: 30px;
}
.center-buttons-footer-modal {
  text-align: center;
  margin-right: 1px !important;
}
.confirmation-dialog .modal-dialog {
  width: 530px;
}
.margin-top-15 {
  margin-top: 15px;
}
.text-explanation {
  padding: 0px 13px;
}
.forgot-password-text {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 14px !important;
  line-height: 1.42857143 !important;
}
.blue-ks {
  background: #1386ac !important;
}
.white-text-ks {
  color: #fff !important;
}
.btn-signin {
  display: inline-block !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: center !important;
  white-space: nowrap !important;
  touch-action: manipulation !important;
  cursor: pointer !important;
  user-select: none !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  padding: 0 22px !important;
  outline: 0 !important;
}
